@import './src/scss/variables';
@import './src/scss/game';

$grid_cell_size: calc(131px - (var(--grid-size) * 8px));

@import '../assets/cells';

:root {
  --grid-size: 4;
}

$max_grid_size: 8;

@import 'grid';
@import 'tile';
@import 'keyframes';
@import 'dark-theme';

.game__content {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  cursor: default;
  user-select: none;
}

.game__container {
  position: relative;
}

.current-score {
  position: relative;
}