@function exponent($base, $exponent) {
  $value: $base;

  @if $exponent > 1 {
    @for $i from 2 through $exponent {
      $value: $value * $base;
    }
  }

  @if $exponent < 1 {
    @for $i from 0 through -$exponent {
      $value: $value / $base;
    }
  }

  @return $value;
}

@function pow($base, $exponent) {
  @return exponent($base, $exponent);
}

.game__content.show-tile-numbers {
  .tile {
    text-indent: unset !important;
  }
}

@import 'sprite-1', 'sprite-2';