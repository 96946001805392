
$dir: '2';
$sprite_length: 16;

.tile.tile-option-#{$dir} {
  background-image: url('../assets/cells/sprite-#{$dir}.png');
  background-repeat: no-repeat;
  background-position-y: 0;
  background-size: cover !important;
  text-indent: -9999px;
  
  @for $i from 1 through $sprite_length + 1 {
    &-#{pow(2, $i)} {
      background-position-x: calc(#{($i - 1) * 6.25%}) !important;
    }
  }
}